document.addEventListener("DOMContentLoaded", function () {
  console.log('js running');

	window.setPlay = (e) => {
		e.play();
	}

	window.setPause = (e) => {
		e.pause();
	}

	var modalTrigger = document.getElementsByClassName('js-modalTrigger');
	var modalClose = document.getElementsByClassName('js-modalClose');
	var modalID = document.getElementById('modal-16');
	var bodyEl = document.getElementById('test');
	var videos = document.getElementsByTagName("iframe");

	//console.log(modalTrigger);
	//console.log(modalClose);
	//console.log(modalID);
	//console.log(bodyEl);

	//console.log(location.hash);
	//window.location.hash = 'modal-16';
	//window.location.href = '#modal-16';
	//modalID.scrollIntoView({ behavior: "smooth", })

	if(location.hash != ''){
		var modalId = document.getElementById(location.hash.replace('#',''));
		modalId != null ? modalId.nextElementSibling.style.display = "block" : window.location.hash = '';
	}

	window.openModal = (e) => {
		e.nextElementSibling.style.display = "block";
		window.location.hash = e.id;
		//gtag('event', e.id);
		//gtag('send', 'pageview', e.id);
		//bodyEl.classList.add('u-locked');
	}

	window.closeModal = (e) => {
		for(var i = 0; i < videos.length; i++) {
			var video = videos[i];
			var player = new Vimeo.Player(document.getElementById(video.id));
			player.unload();
		}
		e.parentElement.parentElement.parentElement.style.display = "none";
		history.replaceState(null, null, ' ');
		//bodyEl.classList.remove('u-locked');
	}
});